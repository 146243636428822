import React, { useState } from "react";
import { Container, Image, Modal } from "react-bootstrap";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import vizo1 from "../assets/vizo1.jpg"
import vizo2 from "../assets/vizo2.jpg"
import vizo3 from "../assets/vizo3.jpg"
import vizo4 from "../assets/vizo4.jpg"
import shelev from '../assets/shelev.jpg'
import specl from '../assets/specl.jpg'
import chromo1 from '../assets/chromo.jpg'
import chromo2 from '../assets/chromo1.jpg'
import dans from '../assets/dans.jpg'
import dans1 from '../assets/dans1.jpg'
import autoref1 from '../assets/autoref1.jpg'
import autoref2 from '../assets/autoref2.jpg'
import autoref3 from '../assets/autoref3.jpg'
import lic1 from '../assets/lic1.jpg'
import lic2 from '../assets/lic2.jpg'
import lic3 from '../assets/lic3.jpg'
import vrach from '../assets/vrach.jpg'
import '../styles/equipment.css';
import {Accordion} from "react-bootstrap";
import {SERVICES_ROUTE} from "../utils/consts";

const images = [
    {
        src: lic1,
        alt: "Image 1"
    },
    {
        src: lic2,
        alt: "Image 2"
    },
    {
        src: lic3,
        alt: "Image 3"
    }
];
const chromoter = [
    {
        src: chromo1,
        alt: "Chromo1"
    },
    {
        src: chromo2,
        alt: "Chromo2"
    }
]
const danster = [
    {
        src: dans,
        alt: "Dans1"
    },
    {
        src: dans1,
        alt: "Dans2"
    }
]
const oborudovanie = [
    {
        src: vizo2,
        alt: "Image 1"
    },
    {
        src: vizo3,
        alt: "Image 2"
    },
    {
        src: vizo4,
        alt: "Image 3"
    }
]
const oborudovanie1 = [
    {
        src: autoref1,
        alt: "Image 4"
    },
    {
        src: autoref2,
        alt: "Image 1"
    },
    {
        src: autoref3,
        alt: "Image 2"
    }
]

function Equipment() {
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState({});

    const handleClose = () => setShowModal(false);
    const handleShow = image => {
        setSelectedImage(image);
        setShowModal(true);
    };

    return (
        <div className='pacientam px-4'>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
                <Col sm={4}>
                    <Nav variant="pills" className="flex-column tabiki">
                        <Nav.Item>
                            <Nav.Link eventKey="first">Оборудование</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="second">Сертификаты и лицензии</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="third">Персонал</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="fourth">Частые вопросы</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col>
                <Col sm={7} className='equip'>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <Row>
                                <Col>
                                    <h1>Афторефрактометр</h1>
                                    <br/>
                                    <p>
                                        Авторефрактометры используются для быстрого
                                        определения объективной рефракции с
                                        необходимым уровнем точности, а также для
                                        измерения межзрачкового расстояния и
                                        диагностики астигматизма. Аппараты,
                                        совмещенные с автоматическими кератометрами,
                                        называются рефрактокератометрами, или
                                        авторефкератометрами. Врачи-офтальмологи и
                                        оптометристы используют эти приборы в своей
                                        повседневной практике для подбора очков, чтобы
                                        существенно ускорить и облегчить свою работу.
                                        <br/>
                                        <br/>
                                        <strong>Афторефрактометр</strong> — прибор
                                        для объективного исследования клинической
                                        рефракции глаза, определения кривизны роговицы,
                                        межзрачкового расстояния, ширины зрачка и
                                        размера роговицы. Это фактически режим
                                        осмотра глаза в проходящем свете.
                                    </p>
                                </Col>
                            </Row>
                            <Row className='justify-content-center galer'>
                                {oborudovanie1.map((oborudovanie1, index) => (
                                    <Col key={index} xs={6} md={4}>
                                        <Image src={oborudovanie1.src} alt={oborudovanie1.alt} className='imggaler' thumbnail onClick={() => handleShow(oborudovanie1)} />
                                    </Col>
                                ))}
                            </Row>
                            <br/>
                            <hr className='liniya'/>
                            <br/>
                            <Row>
                                <Col>
                                    <h1>Визотроник</h1>
                                    <br/>
                                    <p>

                                   <strong>Визотроник</strong> — офтальмологический тренажер, целью которого является тренировка и расслабление цилиарной мышцы, аккомодационного аппарата и глазодвигательных мышц в целом. <img src={vizo1} alt="" className='leftfloat'/>Устройство состоит из набора сферических, цилиндрических и призматических линз, а также световых импульсов красного, зеленого и синего спектра, действие которых программируется тремя программами.
                                    Стойкий эффект достигается за счет стойкого расслабления цилиарной мышцы, повышения тренированности глазодвигательных мышц, улучшения кровоснабжения, ускорения восстановительных процессов в мышечных тканях. Благодаря действию Визотроника повышается работоспособность, активизируются адаптационные процессы в зрительной системе самого глаза.
                                    В Визотронике существует определенный алгоритм действия оптических линз и светотерапии. В зависимости от нарушения зрительной системы врач-офтальмолог выбирает определенную последовательность воздействия оптических линз для тренировки глаз.

                                        <br/><br/>
                                        <ul>
                                        <li>Положительная сферическая линза создает эффект микротумана, который помогает расслабить цилиарную мышцу.</li>
                                        <li>Действие сфероцилиндрических линз создает условия для нормализации работы аккомодационного аппарата.</li>
                                        <li>Призматические линзы создают эффект дивергентной декоммодификации, то есть линзы разводят зрительные оси в противоположные стороны, происходит дублирование изображения, этому эффекту начинают сопротивляться мышцы глаза. Таким образом тренируются глазодвигательные мышцы.</li>
                                        <li>В самом начале процедуры на глаза воздействуют светом красного спектра, который возбуждает орган зрения и улучшает кровообращение, зрачки расширяются, а затем зеленый и синий свет расслабляет глазные мышцы. Зеленый свет используется в середине тренировки, а синий — в конце.</li>
                                        </ul>

                                    </p>
                                </Col>
                            </Row>
                            <Row className='justify-content-center galer'>
                                {oborudovanie.map((oborudovanie, index) => (
                                    <Col key={index} xs={6} md={4}>
                                        <Image src={oborudovanie.src} alt={oborudovanie.alt} className='imggaler' thumbnail onClick={() => handleShow(oborudovanie)} />
                                    </Col>
                                ))}
                            </Row>
                            <Modal show={showModal} onHide={handleClose} centered>
                                <Modal.Body>
                                    <Image src={selectedImage.src} alt={selectedImage.alt} fluid />
                                </Modal.Body>
                            </Modal>
                            <br/>
                            <hr className='liniya'/>
                            <br/>
                            <Row>
                                <Col>
                                    <h1>Щелевая лампа</h1>
                                    <br/>
                                    <p>
                                        <strong>Щелевая лампа — один из главных инструментов диагностики в арсенале офтальмологов и оптометристов.</strong>
                                        <img src={shelev} alt="" className='leftfloat'/> Этот прибор позволяет проводить биомикроскопию видимых частей глаза: роговицы и склеры, конъюнктивы и век, радужки и хрусталика.
                                    </p>
                                    <p>
                                        <strong>КАКИЕ БОЛЕЗНИ ВЫЯВЛЯЮТСЯ?</strong>
                                        <br/>
                                        Щелевая лампа применяется для определения многочисленных заболеваний. Перечислим основные:
                                        <ul>
                                        <li>патология радужной оболочки</li>
                                        <li>глаукома</li>
                                        <li>воспаление век</li>
                                        <li>опухоль</li>
                                        <li>склерит</li>
                                        <li>катаракта</li>
                                        <li>дистрофические изменения роговицы</li>
                                        </ul>
                                        Биомикроскопия глаза проводится при подготовке к оперативному вмешательству. Дополнительно щелевая лампа используется после операции. При наличии травм данная методика также актуальна. Исследования осуществляются методом прямого или непрямого освещения диффузным светом. Для обнаружения инородных тел в глазу комбинируется два способа. Для определения зоны отечности применяется методика отраженного света.
                                        Осмотр на щелевой лампе – наилучший способ увидеть ткани глаза в увеличенном размере. Биомикроскопия совершенно безопасна и практически не имеет противопоказаний. С ее помощью врачи диагностируют большую часть заболеваний органов зрения.
                                    </p>
                                </Col>
                            </Row>
                            <br/>
                            <hr className='liniya'/>
                            <br/>
                            <Row>
                                <Col>
                                    <h1>СПЕКЛ -М</h1>
                                    <br/>
                                    <p>
                                       <strong>СПЕКЛ-М </strong>— лазерный аппарат офтальмотерапевтический для лечения методом стимуляции сетчатки амблиопии и других рефракционных заболеваний у детей и подростков.
                                        <img src={specl} alt="" className='leftfloat'/> Этот прибор позволяет проводить биомикроскопию видимых частей глаза: роговицы и склеры, конъюнктивы и век, радужки и хрусталика.
                                    </p>
                                    <p>
                                        В аппарате Спекл-М реализован метод
                                        лазерплеоптического воздействия. Этот метод
                                        имеет ряд преимуществ перед прочими
                                        распространенными в настоящее время методами
                                        лечения амблиопии. Помимо светового
                                        воздействия, лазерплеоптическое лечение
                                        улучшает частотно-контрастную характеристику
                                        зрительного анализатора за счет воздействия на
                                        него лазерной спекл-структурой (контрастной
                                        движущейся итерференционной структурой) Эа
                                        структура образуется на сетчатке без участия
                                        оптического аппарата глаза, что позволяет
                                        образовать четкое ретинальное изображение даже
                                        при помутнении оптических сред глаза, узком и
                                        дислоцированном зрачке.
                                        <br/><br/><strong>Лазеры разных (синего, красного и зеленого)
                                        цветов, используемые в аппарате Спекл-М,
                                        значительно повышают эффективность
                                        воздействия!</strong>
                                    </p>
                                </Col>
                            </Row>
                            <br/>
                            <hr className='liniya'/>
                            <br/>
                            <Row>
                                <Col>
                                    <h1>Хромотерапия</h1>
                                    <br/>
                                    <p>
                                        <b>Хромотерапия</b> - это метод лечения, использующий различные цвета и их комбинации для улучшения физического и психического здоровья человека. Процедуры хромотерапии могут проводиться с использованием различных источников света, таких как лампы, фонари, лазеры и т.д. Каждый цвет имеет свои специфические свойства и влияет на организм по-разному.

                                       <br/><br/> Существует несколько основных цветов, которые используются в хромотерапии:

                                        <br/><b>Красный:</b> повышает кровообращение и стимулирует иммунную систему, улучшает аппетит и общее настроение.

                                        <br/><b>Оранжевый:</b> снимает депрессию, улучшает работу желудочно-кишечного тракта, стимулирует пищеварение и улучшает метаболизм.

                                        <br/><b>Желтый:</b> улучшает настроение, уменьшает тревожность, повышает концентрацию, стимулирует пищеварение.

                                        <br/><b>Зеленый:</b> успокаивает нервную систему, снижает стресс, улучшает сон, облегчает боль и усталость.

                                        <br/><b>Голубой:</b> снимает болевые ощущения, снижает тревожность и улучшает сон.

                                        <br/><b>Фиолетовый:</b> уменьшает тревожность, улучшает настроение, стимулирует кровообращение и общий метаболизм.

                                        <br/><br/>В хромотерапии цвета могут использоваться отдельно или в комбинации друг с другом, в зависимости от целей лечения и состояния здоровья пациента. Процедуры хромотерапии могут проводиться как врачами-специалистами, так и самостоятельно дома с помощью специальных устройств.

                                        <br/><br/>Применение хромотерапии может иметь ряд пользы для здоровья человека, в том числе:
                                        <ul>
                                            <li>Снижение уровня стресса и тревожности</li>
                                            <li>Улучшение настроения и общего самочувствия</li>
                                            <li>Снижение болевых ощущений и усталости</li>
                                            <li>Улучшение сна и снижение бессонницы</li>
                                            <li>Стимулирование иммунной системы</li>
                                            <li>Улучшение пищеварения и метаболизма</li>
                                            <li>Повышение энергии и выносливости</li>
                                            <li>Улучшение кровообращения и снижение кровяного давления</li>
                                            <li>Снижение уровня холестерина в крови</li>
                                            <li>Повышение концентрации и улучшение памяти</li>
                                            <li>Улучшение цветовосприятия и зрения</li>
                                            <li>Помощь в лечении определенных заболеваний, таких как мигрень, артрит, бронхиальная астма, диабет и другие.</li>
                                        </ul>
                                        <br/>Хромотерапия также может быть использована в качестве дополнительной терапии к другим методам лечения, таким как массаж, физиотерапия, акупунктура и т.д. Однако, перед применением хромотерапии для лечения какого-либо заболевания, необходимо проконсультироваться со специалистом и убедиться в ее безопасности и эффективности в конкретном случае.
                                       <br/><br/> Несмотря на все пользу хромотерапии, следует учитывать, что она не является альтернативой к стандартной медицинской помощи, а скорее дополнением к ней. Также необходимо соблюдать меры предосторожности при использовании хромотерапии, такие как избегание длительного воздействия ярких цветов на глаза, особенно у людей, страдающих заболеваниями глаз.
                                    </p>
                                </Col>
                            </Row>
                            <Row className='justify-content-center galer'>
                                {chromoter.map((chromoter, index) => (
                                    <Col key={index} xs={6} md={4}>
                                        <Image src={chromoter.src} alt={chromoter.alt} className='imggaler' thumbnail onClick={() => handleShow(chromoter)} />
                                    </Col>
                                ))}
                            </Row>
                            <br/>
                            <hr className='liniya'/>
                            <br/>
                            <Row>
                                <Col>
                                    <h1>ДЭНС терапия</h1>
                                    <br/>
                                    <p>
                                        <strong>Динамическая электронейростимуляция (ДЭНС)</strong> — это процедура, при которой на кожу воздействуют электрическими импульсами через электроды. Эти импульсы создают приятные ощущения, которые могут помочь уменьшить болевые ощущения, снять стресс и улучшить настроение.
                                        Процедура ДЭНС проводится с помощью специальных устройств, которые могут быть программированы для создания различных видов электрических импульсов. Электроды устанавливаются на коже в области, где есть болевые ощущения или где нужно уменьшить напряжение мышц. Длительность процедуры и количество сеансов зависит от индивидуальных потребностей пациента и может быть рекомендовано специалистом.
                                    </p>
                                    <p>
                                        ДЭНС может быть использована для лечения различных состояний, включая хроническую боль, мигрени, боли в спине и шее, болезни опорно-двигательного аппарата, невропатическую боль, а также для уменьшения стресса и тревожности.
                                        <br/><br/>
                                        ДЭНС работает путем стимуляции нервной системы, что может помочь снизить уровень боли и улучшить настроение. Она также может способствовать выработке естественных болеутоляющих и успокаивающих веществ, таких как эндорфины и серотонин.
                                            <br/><br/>
                                        <b>Однако</b>, перед использованием ДЭНС для лечения какого-либо заболевания, необходимо проконсультироваться со специалистом и убедиться в ее безопасности и эффективности в конкретном случае.
                                    </p>
                                </Col>
                            </Row>
                            <Row className='justify-content-center galer'>
                                {danster.map((danster, index) => (
                                    <Col key={index} xs={6} md={4}>
                                        <Image src={danster.src} alt={danster.alt} className='imggaler' thumbnail onClick={() => handleShow(danster)} />
                                    </Col>
                                ))}
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <Container>
                                <Row>
                                    <h1>Сертификаты и лицензии</h1>
                                    <div className='col-md-auto'>
                                        <p>
                                            Наша оптика имеет все необходимые лицензии и разрешения на осуществление деятельности в сфере продажи оптических изделий. Мы уделяем большое внимание качеству наших услуг и продукции, поэтому имеем все необходимые сертификаты качества. Вы можете быть уверены, что приобретая у нас оптические изделия, вы получаете продукцию высокого качества и услуги на высшем уровне.
                                        </p>
                                    </div>
                                </Row>
                                <Row className='justify-content-center galer'>
                                    {images.map((image, index) => (
                                        <Col key={index} xs={6} md={4}>
                                            <Image src={image.src} alt={image.alt} className='imggaler' thumbnail onClick={() => handleShow(image)} />
                                        </Col>
                                    ))}
                                </Row>
                                <Modal show={showModal} onHide={handleClose} centered>
                                    <Modal.Body>
                                        <Image src={selectedImage.src} alt={selectedImage.alt} fluid />
                                    </Modal.Body>
                                </Modal>
                            </Container>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            <Row>
                                <h1>Персонал</h1>
                                <Col>
                                    <p>
                                        Приём ведёт врач высшей категории Бобровская
                                        Наталья Петровна <img src={vrach} alt="" className='leftfloat'/>
                                        <br/><br/>В услуги нашего медицинского центра входит:
                                        <ul>
                                            <li>консультация</li>
                                            <li>авторефрактометрия</li>
                                            <li>обследование на глаукому</li>
                                            <li>обследование на катаракту</li>
                                            <li>подбор очков любой сложности</li>
                                            <li>измерение ВГД</li>
                                            <li>массаж век</li>
                                            <li>осмотр глазного дна</li>
                                            <li>определение полей зрения</li>
                                            <li>магнитотерапия</li>
                                            <li>компьютерная диагностика</li>
                                            <li>занятия по Дашевскому</li>
                                            <li>занятия по Аветисову</li>
                                            <li>хромотерапия</li>
                                            <li>деннас-терапия</li>
                                            <li>спекл-М</li>
                                            <li>лечение на аппарате «Визотроник МЗ»</li>
                                        </ul>
                                    </p>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth">
                            <h1>ЧаВо</h1>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><b><p>В чем отличие обычных линз от антибликовых ?</p></b></Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            Антибликовое покрытие, оно же просветляющее, предназначено для улучшения светопропускания линзы и повышения контрастности изображения за счет блокировки бликов. Оно также снижает нагрузку на глаза, сокращая количество бликов на линзе. Особенно это заметно при вождении автомобиля – просветляющее покрытие смягчает блики от фар встречных машин. Визуально выглядят намного лучше чем линзы без покрытия.
                                                    <br/><br/>
                                            Показания к использованию антибликовых очков
                                            <br/><br/>
                                           • регулярное и длительное использование гаджетов: телефоны, планшеты, компьютеры и прочее;
                                            <br/>  • склонность к пересыханию слизистой оболочки глаз;
                                            <br/>  • ощущение рези или жжения;
                                            <br/>  • повышенная слезоточивость;
                                            <br/>  • эффект песка в глазах;
                                            <br/>  • светобоязнь;
                                            <br/>  • покраснение;
                                            <br/> • хроническое переутомление глаз.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><b><p>В чем отличие полимиризованных солнцезащитных очков от неполимеризованных? </p></b></Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            Поляризационные очки отличаются от обычных солнцезащитным тем, что защищают не только от вертикальных солнечных лучей, но и горизонтальных. Это достигается за счёт дополнительной тончайшей жидкокристаллической плёнки, которая обеспечивает глазам полную защиту от бликов.
                                                <br/><br/>
                                            То есть разница между обычными солнцезащитными и поляризационными очками в том, что последние имеют плюс ещё одну дополнительную функцию.
                                            <br/><br/>
                                            В чём разница при ношении солнцезащитных очков с поляризацией и без
                                           <br/> Если после обычных солнцезащитных очков вы наденете поляризационные, вы сразу почувствуете, в чём их отличия:
                                           <br/> • Более чёткое и контрастное изображение за счёт блокировки всех мешающих отблесков;
                                           <br/> • Снижение утомляемости глаз, которое достигается благодаря отсутствию раздражающих бликов, необходимости щуриться и напрягаться;
                                           <br/> • Комфортная видимость и отличная ориентация при любой погоде.
                                            <br/><br/>
                                            Они пригодятся:
                                           <br/> 1. водителям, чтобы защитить глаза от ослепляющего света встречных фар, ярких неоновых вывесок, отблесков на дорогах после дождя или снега.
                                           <br/> 2. спортсменам, которые занимаются водными или зимними видами спорта.
                                           <br/> 3. рыбакам, чтобы комфортно следить за поплавком, не щурясь и не напрягая при этом глаза.
                                           <br/> 4. на отдыхе у моря, возле реки или озера. Очки с поляризацией «отгородят» вас от сверкания водной глади и сделают времяпрепровождение более комфортным.
                                           <br/> 5. при посещении горнолыжного курорта, так как вам не придётся опасаться «снежной слепоты», которая может поразить глаза.
                                           <br/> 6. даже при обычной прогулке по городу. В поляризованных очках, в отличие от обычных солнцезащитных, вас не сможет дезориентировать случайный отблеск отражённого света.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><b><p>Как ухаживать за мягкими контактными линзами?</p></b></Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            1. Наполните контейнер
                                            свежим дезинфицирующим раствором для контактных линз перед каждым использованием.
                                           <br/> 2. Очистите первую линзу,
                                            положив ее на чистую и сухую ладонь. Нанесите несколько капель дезинфицирующего раствора и одним пальцем слегка потрите линзу, очищая ее поверхность
                                           <br/> 3. Сполосните
                                            линзу еще несколькими каплями раствора. Переверните линзу и повторите процедуру с другой стороны линзы. Потом опять сполосните линзу и после этого положите ее в чистый контейнер.
                                           <br/> 4. Повторите
                                            процедуру с другой линзой, закройте контейнер и оставьте линзы для дезинфекции. Подробную информацию о том, как надолго оставлять линзы в растворе или как часто менять раствор, если вы не носите линзы каждый день, вы найдете в инструкции к конкретному раствору.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><b><p>Как часто нужно посещать офтальмолога? </p></b></Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            Обязательно нужно обследовать детей сразу после рождения, в полгода, в 3 года и перед поступлением в школу. Школьникам следуют проходить проверку зрения каждый год. Многие глазные заболевания, нарушения зрения и снижение его остроты возникают ещё в детстве, и при своевременном и правильном лечении они могут быть полностью скорректированы.
                                            <br/>Взрослым людям с 18 до 64 лет, у которых нет проблем со зрением, следует посещать офтальмолога раз в 2 года для профилактического осмотра. А людям старшего возраста, после 65 лет, необходимо проверять зрение 1 раз в год.
                                            <br/><br/><b>Кому стоит обратить особое внимание на регулярную проверку зрения?</b>
                                            <br/>Уделите особое внимание регулярной проверке зрения, если:
                                           <br/> • у вас были какие-либо травмы или заболевания глаз, а также, если вы перенесли операцию на глаза;
                                            <br/>• у ваших родственников была глаукома или катаракта;
                                            <br/>• вы планируете беременность;
                                            <br/>• вы проходите длительный курс лечения гормональными препаратами;
                                            <br/>• у вас есть заболевания нервной или сосудистой системы;
                                           <br/> • у вас пониженное давление.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header><b><p>Когда назначается физиолечение?</p></b></Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            Физиотерапия назначается для лечения и профилактики ряда заболеваний глаз, коррекции зрения.
                                            <br/>Физиопроцедуры – безопасный, безоперационный и надежный способ терапии. Посещение курса аппаратных процедур не требует подготовки и реабилитации.
                                            <br/><a className='text-decoration-none' href={SERVICES_ROUTE}>Здесь вы можете ознакомиться какие услуги мы предлагаем.</a>
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header><b><p>В каких случаях нельзя проходить физиолечение?</p></b></Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            Противопоказаниями к проведению физиолечения служат такие заболевания, как:  Эпилепсия, онкологических заболевания, температура, воспаления глаз.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header><b><p>Даете ли вы справки на физкультуру?</p></b></Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            Да, конечно! Мы являемся медицинским центром, что дает нам право освобождать ребенка от занятий физической культурой.
                                            <br/><br/>
                                            Если взрослый или ребёнок регулярно занимается спортом в общественной спортивной секции и вследствие занятий у него ухудшается состояние здоровья, мы выписываем справку, освобождающую ребенка/взрослого от занятий спортом. Данная справка выписывается нашим врачом после прохождения взрослым и ребёнком медицинского профосмотра.
                                           <br/><br/> Кроме того, если у ребёнка присутствуют какие-либо инфекционные заболевания, он может стать источником заражения для других детей. Исключить подобные риски также призвана справка о допуске к занятиям спортом.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
        </div>
    );
}

export default Equipment;
